import React, {useState} from "react";

const Context = React.createContext();

function ContextProvider({children}) {

    const [menuOpen, setMenuOpen] = useState(false);

    function openMenu() {
        setMenuOpen(true);
    }

    function closeMenu() {
        setMenuOpen(false);
    }

    return (
        <Context.Provider value={{
            menuOpen,
            openMenu,
            closeMenu
        }}>
            {children}
        </Context.Provider>
    )
}

export {ContextProvider, Context};