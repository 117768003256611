import React, {useContext} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import {Context} from './Context';

import Home from './pages/Home';
import Service from './pages/Service';
import Contact from './pages/Contact';

import MenuIcon from './components/MenuIcon';
import Menu from './components/Menu';
import Footer from './components/Footer';

import './App.css';


function App() {
  const {menuOpen} = useContext(Context);
  return (
    <Router>
      <div className={"page-container"}>
        <div className={'content-wrap'}>
          {
            menuOpen ? 
            <Menu width={'240px'}/> :
            <Menu width={'0px'}/>
          }
          <MenuIcon />
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route exact path="/service"><Service /></Route>
            <Route exact path="/contact"><Contact /></Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
