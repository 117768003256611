import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as CloseIcon} from '../assets/close.svg'

import {Context} from '../Context';

import './Menu.css';

function Menu(props) {
    const {closeMenu} = useContext(Context);
    return (
        <div className={'nav-container'} style={{width: props.width}}>
            <div className={'close-icon'} onClick={closeMenu}>
                <CloseIcon />
            </div>
            <header className={'menu'}>
                <Link to="/" onClick={closeMenu}>
                    Home
                    <span></span>
                </Link>
                <Link to="/service" onClick={closeMenu}>
                    Service
                    <span></span>
                </Link>
                <Link to="/contact" onClick={closeMenu}>
                    Contact
                    <span></span>
                </Link>
            </header>
        </div>
    )
}

export default Menu;