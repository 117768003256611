import React from 'react';

import {ReactComponent as PhoneIcon} from '../assets/phone.svg';
import {ReactComponent as EmailIcon} from '../assets/e-mail.svg';

import ContactForm from '../components/ContactForm';

import './Contact.css';

function Contact() {
    return (
        <div className={"contact-container"}>
            <div className={'contact-text-container'}>
                <div className={"callout-container"}>
                    <div id={'lets'}>Let's</div>
                    <div id={'create'}>CREATE</div>
                    <div id={'together'}>Together</div>
                </div>
                <div className={'contact-info-container'}>
                    <div>
                        <PhoneIcon />
                        <span>+1 (226)-224-6839</span>
                    </div>
                    <div>
                        <EmailIcon />
                        <span>info@ccdigital.ca</span>
                    </div>
                </div>
            </div>
            <ContactForm />
        </div>
    )
}

export default Contact;