import React, {useState} from "react";

import './ContactForm.css';

function ContactForm() {
    const [formInput, setFormInput] = useState({
            name: "",
            email: "",
            message: ""
        }
    )

    function handleChange(e) {
        const {name, value} = e.target;
        setFormInput({
            ...formInput,
            [name]: value
        })
    }

    function submitForm() {
        console.log('Sending email...');
        // console.log(formInput);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/post`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: formInput.name,
                email: formInput.email,
                message: formInput.message
            })
        })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Failed to send email.');
            }
            return res.json();
        })
        .then(resData => {
            console.log(resData);
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
        <div className={'contact-form-container'}>
            <div className={'input-container'}>
                <input 
                    name="name"
                    value={formInput.name}
                    placeholder="Your name*"
                    onChange={handleChange}
                />
                <input 
                    name="email"
                    value={formInput.email}
                    placeholder="E-mail address*"
                    onChange={handleChange}
                />
            </div>
            <textarea 
                name="message"
                value={formInput.message}
                placeholder="We would like to know more about your journey!"
                onChange={handleChange}
            />
            <button onClick={submitForm}>CREATE</button>
        </div>
    )
}

export default ContactForm;