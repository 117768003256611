import React from 'react';


import ServiceSection from '../components/ServiceSection';

import graphicDesignPic from '../assets/graphic-design.jpg';
import uxDesignPic from '../assets/ux-design.jpg';
import developmentPic from '../assets/development.jpg';
import renderingPic from '../assets/3d-rendering.jpg';
import motionGraphPic from '../assets/motion-graph.jpg';
import videoEditPic from '../assets/video-editing.jpg';

import './Service.css';

function Service() {

    return (
        <div className={'service-container'}>
            <div className={'service-header'}>
                <div>
                    Our
                </div>
                <div id={'creative'}>
                    Creative
                </div>
                <div>
                    Sauce
                </div>
            </div>
            <ServiceSection 
                img={graphicDesignPic}
                title={'Graphic Design'}
                content={'Our graphic design team combines modern technologies and artistic senses to create brand identity and express your visions.'}
            />
            <ServiceSection 
                img={uxDesignPic}
                title={'UI/UX Design'}
                content={'We take a step further, creating high-fidelity prototypes to test and simulate the best user experience of your digital product.'}
            />
            <ServiceSection 
                img={developmentPic}
                title={'Web & Mobile Development'}
                content={'Our tech savvy team works closely with you to develop the product that empowers your business.'}
            />
            <ServiceSection 
                img={renderingPic}
                title={'3D Modeling & Rendering'}
                content={'With our 3D modeling and rendering services, we help you in creating an immersive experience of your products and services.'}
            />
            <ServiceSection 
                img={motionGraphPic}
                title={'Motion Graphics'}
                content={'Things are more interesting when they move! We create motion graphics that bring life into your brands.'}
            />
            <ServiceSection 
                img={videoEditPic}
                title={'Video Editing'}
                content={'With a little more touch, we create videos that express the souls of your products and services that resonate with your customers.'}
            />
        </div>
    )
}

export default Service;