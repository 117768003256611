import React from 'react';

import {ReactComponent as InstagramIcon} from '../assets/instagram.svg';
import {ReactComponent as TwitterIcon} from '../assets/twitter.svg';
import {ReactComponent as PinterestIcon} from '../assets/Pinterest.svg';
import {ReactComponent as TiktokIcon} from '../assets/logo-tiktok.svg';

import './Footer.css';

function Footer() {
    return (
        <div className={'footer'}>
            <span className={'footer-top-line'}></span>
            <div className={'footer-content-container'}>
                <span>©2021 C.C. DIGITAL LAB. ALL RIGHTS RESERVED</span>
                <div className={'social-media'}>
                    <a href="https://www.instagram.com/cc_digital_lab" target="_blank" rel="noreferrer">
                        <InstagramIcon />
                    </a>
                    <a href="https://twitter.com/CCDigital2" target="_blank" rel="noreferrer">
                        <TwitterIcon />
                    </a>
                    <a href="https://www.pinterest.ca/81e14697f176841522233be1e0e73d/_saved" target="_blank" rel="noreferrer">
                        <PinterestIcon />
                    </a>
                    <a href="https://www.tiktok.com/@cc_digital?lang=en" target="_blank" rel="noreferrer">
                        <TiktokIcon />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;