import React, {useState, useEffect} from 'react';

import './RotateText.css';

function RotateText() {
    const words = ['Graphic Design', 'UI/UX Design', 'Web & Mobile Development', '3D Modeling & Rendering', 'Motion Graphic', 'Video Production'];
    const [wordIndex, setWordIndex] = useState(0);
    const [currentWord, setCurrentWord] = useState([]);
    const [eleClass, setEleClass] = useState([
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind', 
        'letter behind'
    ]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setWordIndex((prev) => {
                if (prev >= words.length-1) {
                    return 0; 
                } else {
                    return prev + 1;
                }
            });
        }, 8000);
        return () => clearInterval(intervalId);
    }, [wordIndex]);

    useEffect(() => {
        // Split text letters
        setCurrentWord(words[wordIndex].split(''));
    }, [wordIndex]);

    // Loop through the currentWord array and returns an array of JS object in <span>
    const charList = currentWord.map((char, i) => {
        return (
            <span key={i} className={eleClass[i]}>{char}</span>
        )
    });

    function letterIn(i) {
        setTimeout(() => {
            setEleClass([
                ...eleClass,
                eleClass[i] = "letter in"
            ])
        }, i*100)
    }

    function letterOut(i) {
        setTimeout(() => {
            setEleClass([
                ...eleClass,
                eleClass[i] = "letter out"
            ])
        }, 2000+i*100)
    }

    function letterBehind(i) {
        setTimeout(() => {
            setEleClass([
                ...eleClass,
                eleClass[i] = "letter behind"
            ])
        }, 4000+i*100)
    }

    useEffect(() => {
        for (let i = 0; i < 26; i++) {
            letterIn(i);
        }
    }, [wordIndex]);

    useEffect(() => {
        for (let i = 0; i < 26; i++) {
            letterOut(i);
        }
    }, [wordIndex]);

    useEffect(() => {
        for (let i = 0; i < 26; i++) {
            letterBehind(i);
        }
    }, [wordIndex]);

    return (
        <div className={'rotating-container'}>
            <p>{charList}</p>
        </div>
    )
}

export default RotateText;