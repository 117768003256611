import React, {useState, useRef} from 'react';
import 'animate.css';

import './ServiceSection.css';

function ServiceSection(props) {
    const [showSection, setShowSection] = useState(false);

    const inputRef = useRef();

    function showScrollY() {
        if (window.scrollY > 150) {
            setShowSection(true);
        } else {
            setShowSection(false);
        }
    };

    window.addEventListener('scroll', showScrollY);
    
    return (
        <div className={'service-section-container'}>
            <div className={showSection ? 'animate__animated animate__slideInLeft service-section-img-container' : 'service-section-img-container inactive'}>
                <img src={props.img} alt={'An image'} />
            </div>
            <div className={showSection ? 'animate__animated animate__slideInRight service-section-text-container' : 'service-section-text-container inactive'}>
                <h3>{props.title}</h3>
                <p>{props.content}</p>
            </div>
        </div>
    )   
};

export default ServiceSection;