import React, {useContext} from "react";

import {Context} from '../Context';

import './MenuIcon.css';

function MenuIcon() {

    const {openMenu} = useContext(Context);

    return (
        <div className={'menu-icon-container'} onClick={openMenu}>
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>
    )
}

export default MenuIcon;