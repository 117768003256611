import React from 'react';

import RotateText from '../components/RotateText';
import Card from '../components/Card';

import lightBulb from '../assets/Light_bulb.svg';
import handShake from '../assets/handshake.svg';
import valueIcon from '../assets/Value.svg';
import expIcon from '../assets/experience.svg';

import './Home.css';

function Home() {
    return (
        <div>
            <div className={'landing-container'}>
                <div id="c1">
                    C
                </div>
                <div id="c2">
                    C
                </div>
                <div id={'digital-lab'}>
                    <span>Digital</span>
                    <span>Lab</span>
                </div>
            </div>
            <div className={'about-container'}>
                <div className={'sentence'}>We are a digital design agency specialized in</div>
                <RotateText />
                <div className={'cards'}>
                    <Card 
                        img={lightBulb}
                        title={'Creative'} 
                        content={'We are a team of innovators, artists and forward thinkers who love to experiment and create fascinating products and user experiences.'}
                    />
                    <Card 
                        img={handShake}
                        title={'Collaborative'} 
                        content={'We listen and work closely with you to create innovative solutions tailored to your needs.'}
                    />
                    <Card 
                        img={valueIcon}
                        title={'Value'} 
                        content={"We strive to be a value adding catalyst in the your journey."}
                    />
                    <Card 
                        img={expIcon}
                        title={'Experience'} 
                        content={'We seek to deliver the most sophisticated experience through products and services.'}
                    />
                </div>
            </div>
        </div>
    )
}

export default Home;