import React from "react";

import './Card.css';

function Card(props) {
    return (
        <div className={'homepage-card'}>
            <div className={'card-icon-container'}>
                <img src={props.img} alt=""/>
            </div>
            <h1>{props.title}</h1>
            <p>{props.content}</p>
        </div>
    )
}

export default Card;